import Vue from 'vue';
import VueRouter from 'vue-router';

const DefaultLayout = () => import('@/layouts/default');

const HomePage = () => import('@/pages/index');
const ArticlePage = () => import('@/pages/post/_slug');
const PrivacyPage = () => import('@/pages/page/privacy');

const routes = [
	{
		path: '/', component: DefaultLayout,
		children: [
			{ path: '', component: HomePage },
			{ path: 'category/:category', component: HomePage },
			{ path: 'read/:id', component: ArticlePage },
			{ path: 'privacy', component: PrivacyPage }
		]
	},
];

Vue.use(VueRouter);

export default new VueRouter({
	mode: 'history',
	scrollBehavior (to, from, savedPosition) {
		if (to.hash) {
			return { selector: to.hash };
		} else if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	},
	routes
});
