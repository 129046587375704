import Vue from 'vue'
import App from './App.vue'

import router from '@/modules/router';
import store from '@/modules/store';
import vuetify from '@/modules/vuetify';
import '@/modules/moment';

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store,
  vuetify
}).$mount('#app')
