import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    app: {
      isDrawer: false,
      isSearch: false
    }
  },
  mutations: {
    'app/toggleDrawer': (state, payload) => {
        state.app.isDrawer = payload;
    },
    'app/toggleSearch': (state, payload) => {
        state.app.isSearch = payload;
    }
  },
  plugins: [createPersistedState()]
})
